








import { useDbGetters, useUserGetters } from '@/store';
import PCSelectCard from './PCSelectCard.vue';

export default {
  name: 'Select',

  components: {
    'pc-select-card': PCSelectCard
  },

  setup(_props, ctx) {
    const { collection } = useDbGetters(['collection']);
    const { getObjectId } = useUserGetters(['getObjectId']);

    async function createProgram() {
      await collection.value!('Program')
        .insertOne({
          organizers: [getObjectId.value],
          participants: [],
          dateCreated: new Date(),
          licensed: false
        })
        .then(result => {
          ctx.root.$router.push({
            name: 'guide',
            params: { programId: result.insertedId, page: '0' }
          });
        });
    }

    return { createProgram };
  }
};
