






















































































































































































































































export default {
  name: 'PCSelectCard'
};
